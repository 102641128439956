import * as React from 'react';
import Mobile from "./Mobile";
import PC from "./PC";
import "./index.css";

export default function Official() {
  if (/Android|webOS|iPhone|iPod|BlackBerry/ig.test(navigator.userAgent)) {
    return <Mobile />
  }
  return <PC />
}
