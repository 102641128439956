import * as React from "react";
import { Route, Switch, useRouteMatch, Link, Redirect } from "react-router-dom";
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import Down from "@/pages/common/Down";


export default function Mobile() {
    const { path } = useRouteMatch();
    return <div>
        <Switch>
            <Route exact path={`${path}`} component={MobileHome} />
            <Route exact path={`${path}/down`} component={Down} />
            <Redirect from="*" to={`${path}/down`} />
        </Switch>
    </div>;
}


const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

class MobileHome extends React.Component<{}, { index: Number }> {
    constructor(props) {
        super(props);
        this.state = {
            index: 0,
        }
    }

    changeIndex(index) {
        this.setState({ index });
    }

    render() {
        const images = [
            "https://cdn2.luqudata.com/official/mobile-5.jpg",
            "https://cdn2.luqudata.com/official/mobile-1.jpg",
            "https://cdn2.luqudata.com/official/mobile-2.jpg",
            "https://cdn2.luqudata.com/official/mobile-3.jpg",
            "https://cdn2.luqudata.com/official/mobile-4.jpg",
        ];
        return (
            <div>
                <div className="slide">
                    <AutoPlaySwipeableViews index={this.state.index} onChangeIndex={(index) => this.changeIndex(index)}>
                        {images.map((item, i) => {
                            return (
                                <div key={i}><Link to="/official/down"><img src={item} className="slide-img" /></Link></div>
                            )
                        })}
                    </AutoPlaySwipeableViews>
                    <div className="pagination">
                        {
                            images.map((_, i) => (<div key={i} onClick={() => this.changeIndex(i)} className={i === this.state.index ? "dot active" : "dot"} />))
                        }
                    </div>
                </div>
            </div>
        )
    }
}
