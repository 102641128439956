import { OperationState, OperationTypes, CLICK_OP, JUMP_OP } from "./types";
import { Alert } from "@/components/Modal";
import { history } from "@/store";

const initialState: OperationState = {
  count: 0,
}

export function operationReducer(state = initialState, action: OperationTypes): OperationState {
  switch (action.type) {
    case CLICK_OP:
    case JUMP_OP:
      const count = state.count + 1;
      if (count % 5 === 0) {
        // Alert("下载有据升学APP", "查询更多报考数据", [{ text: "取消" }, { text: "确定", onPress: () => history.push("/entity/down") }])
      }
      return { ...state, count, };
    default:
      return state;
  }
}
