export const ADD_OPERATION = "@@ADD_OPERATION";

export interface OperationState {
  count: number;
}

export const CLICK_OP = "click";
export const JUMP_OP = "jump";
export interface AddOperationAction {
  type: typeof CLICK_OP | typeof JUMP_OP;
}

export type OperationTypes = AddOperationAction;