import * as React from "react";

export default class About extends React.Component<{}, {}> {
  render() {
    return (
      <div className="container">
        <img src="https://cdn2.luqudata.com/official/about.png" />
        <div className="a-content">
          <img src="https://cdn2.luqudata.com/official/about-content.png" />
        </div>
      </div>

    )
  }
}
