import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createBrowserHistory } from "history";

import { accountReducer } from "./account/reducers";
import { operationReducer } from "./operation/reducers";

const rootReducer = combineReducers({
    account: accountReducer,
    operation: operationReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const history = createBrowserHistory()

export default function configureStore() {
    const middlewares = [thunkMiddleware];
    const middleWareEnhancer = applyMiddleware(...middlewares);

    const store = createStore(rootReducer, middleWareEnhancer);

    return store;
}
