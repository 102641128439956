import * as React from "react";

export default function Footer() {
    return (
        <div className="pc-footer">
            <div className="f-content">
                <ul className="clearfix">
                    <li>合作洽谈：hezuo@luqudata.com</li>
                    <li>大学联系：daxue@luqudata.com</li>
                    <li>行家申请：hangjia@luqudata.com</li>
                    <li>升学投稿：tougao@luqudata.com</li>
                    <li>联系电话：020-82007681</li>
                </ul>
                <div className="f-hr" />
                <div className="f-company"> 广州鹿趣科技有限公司 粤ICP备17041933号 </div>
            </div>
        </div>
    )
}

