import * as React from "react";
import { Link, useLocation, useHistory, useRouteMatch } from 'react-router-dom';

export default function Header(props: { title?: string }) {
    let { path } = useRouteMatch();
    const routes = [
        { title: "首页", route: `${path}/` },
        { title: "APP下载", route: `${path}/down` },
        { title: "关于我们", route: `${path}/about` },
    ]

    const { pathname } = useLocation();
    return (
        <div className="header">
            <div className="logo"><img src="https://cdn2.luqudata.com/official/logo.png" /></div>
            <div className="navs">
                <ul className="clearfix">
                    {
                        routes.map((item, i) => <li key={`${i}_${item.route}`}><Link to={item.route} className={pathname === item.route ? "current" : ""}>{item.title}</Link></li>)
                    }
                </ul>
            </div>
        </div>
    )
}

