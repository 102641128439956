import * as React from "react";

export default class Home extends React.Component<{}, {}> {
  render() {
    return (
      <div className="container">
        <img src="https://cdn2.luqudata.com/official/index-1.png" />
        <img src="https://cdn2.luqudata.com/official/index-2.png" />
        <img src="https://cdn2.luqudata.com/official/index-3.png" />
        <img src="https://cdn2.luqudata.com/official/index-4.png" />
        <img src="https://cdn2.luqudata.com/official/index-5.png" />
      </div>
    )
  }
}
