import * as React from 'react';
import Home from "./Home";
import Down from "./Down";
import Abount from "./About";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { Route, Switch, useRouteMatch } from "react-router-dom";

export default function PC() {
    let { path } = useRouteMatch();
    return <div>
        <Header />
        <Switch>
            <Route exact path={`${path}/`} component={Home} />
            <Route exact path={`${path}/about`} component={Abount} />
            <Route exact path={`${path}/down`} component={Down} />
        </Switch>
        <Footer />
    </div>;
}
