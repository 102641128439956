export const LOGIN_REQUEST = "@@LOGIN_REQUEST";
export const LOGIN_FAILURE = "@@LOGIN_FAILURE";
export const LOGIN_SUCCESS = "@@LOGIN_SUCCESS";

export interface AccountState {
  status: string;
  token: string;
  is_login?: boolean;
  app_vip_end_time: number;
}

interface LoginRequestAction {
    type: typeof LOGIN_REQUEST;
    token: string;
}

interface LoginSuccessAction {
  type: typeof LOGIN_SUCCESS
  info: AccountState
}

interface LoginFailAction {
  type: typeof LOGIN_FAILURE
}

export type AccountActionTypes = LoginRequestAction | LoginSuccessAction | LoginFailAction;

