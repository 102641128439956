import { history } from "@/store";

class RPCResponseError extends Error {
  public code: Number;
  constructor(error_code: Number, error_message: string) {
    super(error_message);
    this.code = error_code;
  }
}

export default class RPCClient {
  public static call(path: string, params?: any): Promise<any> {
    return fetch(`${path}`, {
      method: 'post',
      body: JSON.stringify(params || {}),
      headers: { 'Content-Type': 'application/json' },
    }).then(res => res.json()).then(res => {
      if (window["YouJuSdk"] && res.error_code) {
        switch (res.error_code) {
          case 20005:
          case 20002:
          case 10003:
            history.push("/ncee/login");
            window["YouJuSdk"].pushRoute('login');
            break;
          case 10091:
            window["YouJuSdk"].showPointLack();
            break;
        }
      }
      if (window["YouJuSdk"] && res.__minus_point) {
        window["YouJuSdk"].youjuPointChangeToastInfo(res.__minus_point);
      }

      return res;
    })
  }
}
