import * as React from "react";

export default class Down extends React.Component<{}, {}> {
    render() {
        return (
            <div className="container">
                <img src="https://cdn2.luqudata.com/official/down.png" />
            </div>
        )
    }
}
