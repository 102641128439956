import * as React from 'react';
import RPCClient from '@/common/RPCClient';
import "./Down.less"
import { wxShare } from '@/common';

export default class Down extends React.Component<{}, { url: string }> {
  private defaultURL = "https://a.app.qq.com/o/simple.jsp?pkgname=com.luqudata.youju";
  constructor(props) {
    super(props);
    this.state = {
      url: this.defaultURL,
    }
  }

  componentDidMount() {
    document.title = "有据升学APP下载";
    const ua = navigator.userAgent;
    wxShare({ title: "推荐：用户好评率排名前三的志愿填报工具", desc: "16-20年招生计划,专业分,优势专业,分析录取把握,有理有据上大学" })
    RPCClient.call("/api/get_app_download_url", { ua }).then(resp => {
      this.setState(resp);
      if (resp.auto) {
        window.location.href = resp.url || this.defaultURL;
      }
    })
  }

  download() {
    const { url } = this.state;
    window.open(url || this.defaultURL);
    if (!url.startsWith("http")) {
      const timer = setTimeout(() => {
        window.open(this.defaultURL);
      }, 1000);
      const preventDefault = () => {
        clearTimeout(timer);
        window.removeEventListener("focus", preventDefault);
      }
      window.addEventListener("focus", preventDefault);
    }
  }

  render() {
    const { url } = this.state;
    return (<div className="downinfo">
      <div className="headbg"></div>
      <div className="downloadlogo"></div>
      <div className="down-title" style={{ color: "#000" }}>有据升学</div>
      <div className="down-title">高考志愿填报类APP好评率前三</div>
      <div className="download" onClick={() => this.download()}>立即下载</div>
      <div className="dlinfo">
        <p style={{ fontWeight: "bold", fontSize: 14 }}>垂直覆盖全国各省份高考报考录取数据</p>
        <p>2016-2020年高考报考数据精准查询</p>
        <p>垂直覆盖全国各省份高考报考录取数据</p>
        <p>大学投档线，大学分专业录取分</p>
        <p>历年招生计划，各大学优势专业分布</p>
        <p>按专业进行大学录取把握分析</p>
        <p>意向大学各专业录取把握分析</p>
        <p>按高考分数/位次分析大学录取把握</p>
      </div>
    </div>)
  }
}
