import * as React from "react";
import { render } from "react-dom";
import { hot } from "react-hot-loader";
import { Router, Route } from 'react-router-dom';
import { Provider } from "react-redux";
import Official from "./pages/official";
import configureStore, { history } from "./store";

const App = ({ history }) => {
    return (
        <Router history={history}>
            <Route path="/official" component={Official} />
        </Router>
    );
}

const rootEl = document.getElementById("root");
const store = configureStore();

render(
    <Provider store={store}>
        <App history={history} />
    </Provider>,
    rootEl,
);
