import RPCClient from "./RPCClient";

declare let wx;

export function getAgentType() {
  const ua = navigator.userAgent;
  if (/MicroMessenger/ig.test(ua)) {
    return "微信";
  } else if (/qnreading/ig.test(ua)) {
    return "看点快报";
  } else if (/qqbrowser/ig.test(ua)) {
    return "QQ浏览器";
  } else if (/ QQ\//ig.test(ua)) {
    return "QQ";
  } else {
    return "其他";
  }
}

export function wxShare({
  title = "有据升学",
  desc = "好评率前三的高考志愿填报APP",
  link = window.location.href,
  imgUrl = "https://cdn2.luqudata.com/youju_logo.png",
  type = "link",
  success = () => { },
  cancel = () => { },
  dataUrl = undefined,
}) {
  imgUrl = imgUrl || "https://cdn2.luqudata.com/youju_logo.png";

  RPCClient.call("/api/get_wechat_share_config", { url: window.location.href }).then((config) => {
    wx.config(config);
    wx.ready(function () {
      // 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
      wx.updateAppMessageShareData({
        title: title,
        desc: desc,
        link: link,
        imgUrl: imgUrl,
        success: success,
      });
      // 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容（1.4.0）
      wx.updateTimelineShareData({
        title: title,
        link: link,
        imgUrl: imgUrl,
        success: success,
      });
      // 获取“分享到朋友圈”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareTimeline({
        title: title,
        link: link,
        imgUrl: imgUrl,
        success: success,
      });
      // 获取“分享给朋友”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareAppMessage({
        title: title,
        desc: desc,
        link: link,
        imgUrl: imgUrl,
        type: type,
        dataUrl: dataUrl,
        success: success,
      });
      // 获取“分享到QQ”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareQQ({
        title: title,
        desc: desc,
        link: link,
        imgUrl: imgUrl,
        success: success,
      });
      // 获取“分享到腾讯微博”按钮点击状态及自定义分享内容接口
      wx.onMenuShareWeibo({
        title: title,
        desc: desc,
        link: link,
        imgUrl: imgUrl,
        success: success,
        cancel: cancel,
      });
      // 获取“分享到QQ空间”按钮点击状态及自定义分享内容接口（即将废弃）
      wx.onMenuShareQZone({
        title: title,
        desc: desc,
        link: link,
        imgUrl: imgUrl,
        success: success,
        cancel: cancel,
      })
    })

    wx.error(function (res) {
      console.log(res);
    })
  })
}