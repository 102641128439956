import {LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, AccountState, AccountActionTypes} from "./types";

const initialState: AccountState = {
  status: "success",
  token: "",
  app_vip_end_time: 0,
}

export function accountReducer(state=initialState, action: AccountActionTypes): AccountState {
    switch(action.type) {
      case LOGIN_REQUEST:
        return { ...state, status: 'request' };
      case LOGIN_SUCCESS:
        return { ...action.info, status: 'success' };
      case LOGIN_FAILURE:
        return { ...initialState, status: 'fail' };
      default:
        return state;
    }
}
